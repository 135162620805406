/* @import url('assets/fonts/ranade/stylesheet.css'); */
/* @import url('assets/fonts/switzer/stylesheet.css'); */

body {
  margin: 0;
  font-family: 'Switzer-Variable';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.ant-layout {
    background-color: #fff;
}

.ant-btn {
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: none;
  border-radius: 16px;
  font-size: 15px;
  line-height: 20px;
  box-shadow: none;
}

.ant-btn.ant-btn-lg {
  border-radius: 30px;
  height: 60px;
  padding-left: 37px;
  padding-right: 37px;
}

.ant-btn.ant-btn-lg >span{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
}

.ant-input {
  background: #FFFFFF;
  border: 0.5px solid #B4B4B4;
  border-radius: 7px;
}

.ant-modal {
  top: 30px;
}

.ant-modal .ant-modal-content {
  padding: 50px 40px;
}

.ant-modal .ant-modal-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: -0.04em;
}

/* .ant-modal .ant-modal-footer {
  margin: 0px;
} */

.form-title {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 20px;
}


/* SPACE CARD styles */

.space-card-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.image-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.space-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    border-radius: 10px;
}

.spacer-price {
    gap: 3px;
    display: flex;
    align-items: flex-end;
}


.metro-icon-image-space-card {
    stroke: #767676;
        fill: none;
}


.card-title{
    margin-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    /* identical to box height, or 22px */
    color: #000000;
}

.card-metro{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #767676;
}

.card-price-small-text{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */
    color: #000000;
}

.card-price-big-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 27px */
    color: #000000;
}

.space-card-button.ant-btn-icon-only {
    height: 44px;
    width: 44px;
    border-radius: 30px;
}

.page-title{
    font-family: 'Ranade-Variable';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 113%;
    letter-spacing: -0.04em;
    color: #000000;
    margin-bottom: 25px;
}

.clickable {
    color: #007AAE;
}

.clickable:hover {
    cursor: pointer;
    color: #37a0cd;
}

.rowWithSeparator {
    border-bottom: 0.5px solid #B4B4B4;
    padding: 15px 0;
}

.filled-star {
    fill: #DBDE45;
    stroke: #DBDE45;
    /* Change this to the color you want for filled stars */
}

.small-grey-star {
    fill: none;
    stroke: #bbbbbb;
    /* Change this to the color you want for empty stars */
}

.empty-star {
    fill: none;
    stroke: #767676;
    /* Change this to the color you want for empty stars */
}

.ant-avatar-string {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 17px */
    color: #FFFFFF;
}

/* 
.images-container {
    display: flex;
}

.image-wrapper {
    position: relative;
    cursor: pointer;
}

.image-wrapper img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 5px;
}

.overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.plus-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
} */

.non-selectable-carousel-image {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}


/* TYPOGRAPHY */

h1 {
    font-family: 'Ranade-Variable';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 113%;
    /* or 27px */

    letter-spacing: -0.04em;
}

h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    /* identical to box height, or 22px */
    color: #000000;
    margin-bottom: 15px;
}

p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 190%;
    /* or 27px */
    color: #505050;
    white-space: pre-line;
}