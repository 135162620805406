.gm-style .gm-style-iw-d {
    overflow: auto !important ;
    min-height: 300px !important;
    min-width: 300px;
    max-height: fit-content !important;
}
.gm-style .gm-style-iw-c {
     padding: 0 !important;
     -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.25); 
    -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.25);
    max-width: none !important;
    background: none;
    min-height: 300px !important;
    max-height: fit-content !important;
}
 
button.gm-ui-hover-effect {
     visibility: hidden;
}
 